

















import { Vue, Component } from 'vue-property-decorator';
import { placeModule } from '@/store';

@Component
export default class PlaceNotice extends Vue {
  public beforeRouteEnter(to, from, next) {
    placeModule.fetchPlaceInfoData(to.params.placeId);
    next();
  }
  public mounted() {
    const el: any = document.getElementById(this.$route.params.target);
    if (el) {
      el.scrollIntoView();
    }
  }
  get place() {
    return placeModule.placeInfoData;
  }
}
